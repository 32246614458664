import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import Axios from 'axios';

export const TermsAndConditions = () => {
	const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
	const [state, setState] = useState({
		ukLargeWeight: 0,
		usaLargeWeight: 0,
		usaLessWeight: 0,
	});

	useEffect(
		() => {
			Axios.get(`${baseUrl}/calculation-variables`)
				.then(snaps => {
					setState({
						ukLargeWeight: snaps.data.ukLargeWeight,
						usaLargeWeight: snaps.data.usaLargeWeight,
						usaLessWeight: snaps.data.usaLessWeight,
					});
				})
				.catch(() => {
					console.log({ calcErrors: 'An error occured while getting calculation rates.' });
				});
		},
		// eslint-disable-next-line
		[]
	);
	return (
		<Paper style={{ margin: '2%', padding: '2%' }}>
			<p>Please understand we are here to serve you and to get you your stuff as fast as legally possible.</p>
			<p>Now please read the below information very carefully. Any small mistakes could cause a delay.</p>
			<p>
				<strong>
					Any package sent to our US address MUST include a packing list on the outside of the package,
					detailing the contents of the package. The only exception to this requirement is packages sent
					directly from retailers (Amazon, Walmart, Macy's, Target, etc.).
				</strong>
			</p>
			<p>
				<strong>
					Every package is subject to opening and inspection. If you send it to us, we have the right to
					inspect it, and the right to refuse to send it, if you do not include a packing list and follow the
					instructions in this email. Packages are returned to sender at your expense.
				</strong>
			</p>
			<p>
				<strong>
					Please ensure delivery is done between 9am and 3pm. Monday to Friday. No weekend or public holiday
					deliveries. Also, ensure the package is received and signed for.
				</strong>
			</p>
			<p>
				<strong>
					Any item not paid and collected within 7 days of you receiving notice of receipt by Vitumob Shipping
					in Nairobi will incur storage charges of KSh 500 per day for 21 days. After this 21 day period,
					Vitumob Shipping will take ownership of your package.
				</strong>
			</p>
			<hr />
			<h3 style={{ textDecoration: 'underline' }}>US:</h3>
			<strong style={{ textDecoration: 'underline' }}>Rates:</strong>
			<ul>
				<li>US Pricing: </li>
				<li>Up to 2 kg - ${state.usaLessWeight}.</li>
				<li>2 kg or more - ${state.usaLargeWeight} per kg.</li>
			</ul>
			<p>
				<strong>
					Kindly note that you will be charged actual weight or volumetric weight of the package, whichever is
					higher. You can calculate volumetric weight here:
				</strong>
				<a href='https://www.parcelhero.com/en-gb/support/volumetric-weight-calculator'>
					https://www.parcelhero.com/en-gb/support/volumetric-weight-calculator
				</a>
			</p>
			<strong style={{ textDecoration: 'underline' }}>Address:</strong>
			<br />
			<strong>
				Vitumob Shipping
				<br />
				1255 E 16th Street
				<br />
				Suite ** Your client number **
				<br />
				Wilmington, Delaware 19802
				<br />
				USA
			</strong>
			<hr />

			<h3 style={{ textDecoration: 'underline' }}>UK</h3>
			<p>
				<strong style={{ textDecoration: 'underline' }}>Rates:</strong>
				<br />
				&pound;{state.ukLargeWeight} per kg.
			</p>
			<strong style={{ textDecoration: 'underline' }}>Address:</strong>
			<br />
			<strong>
				(Name)
				<br />
				Access Self Storage,
				<br />
				First Way ACG VM ** Your client number **
				<br />
				Wembley HA9 0JD
				<br />
				United Kingdom
			</strong>
			<hr />
			<p>
				Kindly note your Client Number, is unique to you. Your shipments <strong>MUST</strong> include this
				number on the address label of the package. We do not ship items to Kenya that only have a name on the
				label.
			</p>
			<p>
				Your items will be available for pickup in Mombasa Road, Nairobi. Kindly send us a confirmation of
				delivery once your package is delivered to our US warehouse.
				<strong>
					Vitumob Shipping does not ship armaments, illegal goods, drugs, hazardous chemicals, any item
					prohibited by our airline partners or anything considered hazardous according to the IATA Dangerous
					Goods Regulations. We do on the other hand ship lithium-ion batteries contained in equipment (UN
					3481), such as laptops and phones, and Vitumob Shipping does ship colognes and perfumes designated
					as ID8000 dangerous goods. Due to the extra professional packing and legal requirements associated
					with such items, and the additional cost charged us by our airline partners, we charge an additional
					$15 per item fee for each dangerous good you ship with us.
				</strong>
			</p>
			<p>
				Unsure if what you are shipping will be considered a dangerous good? Please consult the IATA online
				regulations here. We have no obligation to return items to you or the sender sent in violation of this
				policy.
			</p>
			<p>
				Vitumob Shipping takes no responsibility for any loss and is not and will not be financially liable for
				any loss of value in transportation or any loss of goods, including the loss incurring by you for loss
				of your goods. Please feel free to reach out with any further questions.
			</p>
		</Paper>
	);
};
